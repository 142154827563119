// extracted by mini-css-extract-plugin
export var access = "company-module--access--e4euC";
export var body = "company-module--body--keyel";
export var contact = "company-module--contact--q323O";
export var content = "company-module--content--HhgV2";
export var gmap = "company-module--gmap--ZYwRj";
export var head = "company-module--head--XfdPD";
export var inner = "company-module--inner--HoBYu";
export var intro = "company-module--intro--d+0NW";
export var overview = "company-module--overview--eL20m";
export var recruit = "company-module--recruit--Bpaeu";
export var ttl = "company-module--ttl--DG7lU";
export var txt = "company-module--txt--DkyhY";