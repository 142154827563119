import * as React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import * as styles from "../styles/company.module.scss"

const Company = () => {
  return (
    <Layout>
      <Seo title="会社情報" description="このページは「会社情報」に関するページです。" />
      <section className="cPageHeader">
        <div className="inner cContainer">
          <div className="content">
            <h2>
              <span className="alphabet">COMPANY</span><br />
              会社情報
            </h2>
          </div>
        </div>
      </section>

      <section className={styles.intro}>
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <div className={styles.head}>
              <h2 className="alphabet">ABOUT</h2>
            </div>
            <div className={styles.body}>
              <p className={styles.ttl}>「WEB3.0」の恩恵を、<br className="sp" />すべての人へ。<br/>ブロックチェーンの専門家として</p>
              <div className={styles.txt}>
                <p>弊社ではブロックチェーンに特化したエンジニアを多く抱え、既存のサービスと同じ水準のシステムを開発することが出来ます。</p>
                <p>また、弊社のもともとのWEB制作・システム開発・マーケティング事業とかけ合わせることで様々なご要望にお答え出来ます。</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.overview} id="overview">
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <div className={styles.head}>
              <h2 className="alphabet">OVERVIEW</h2>
            </div>
            <div className={styles.body}>
              <ul>
                <li>
                  <p className={styles.ttl}>会社名</p>
                  <p className={styles.txt}>ブロックチェーンコネクト</p>
                </li>
                <li>
                  <p className={styles.ttl}>所在地</p>
                  <p className={styles.txt}>〒192-0045 <br/>東京都八王子市大和田町5-24-8</p>
                </li>
                <li>
                  <p className={styles.ttl}>代表取締役社長 CEO</p>
                  <p className={styles.txt}>KAZUKI KIUCHI</p>
                </li>
                <li>
                  <p className={styles.ttl}>事業内容</p>
                  <p className={styles.txt}>
                    ブロックチェーン開発<br/>
                    WEBサイト制作<br/>
                    マーケティング<br/>
                    システム開発<br/>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.access} id="access">
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <div className={styles.head}>
              <h2 className="alphabet">ACCESS</h2>
            </div>
            <div className={styles.body}>
              <ul>
                <li>
                  <p className={styles.ttl}>会社本店所在地</p>
                  <p className={styles.txt}>〒192-0045 <br/>東京都八王子市大和田町5-24-8</p>
                  <div className={styles.gmap}>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.4723233437085!2d139.34942441508022!3d35.66537033844948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60191e09df31d675%3A0xd45c9628f3e08fe7!2z44CSMTkyLTAwNDUg5p2x5Lqs6YO95YWr546L5a2Q5biC5aSn5ZKM55Sw55S677yV5LiB55uu77yS77yU4oiS77yY!5e0!3m2!1sja!2sjp!4v1660040583069!5m2!1sja!2sjp" width="100%" height="100%" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.recruit} id="recruit">
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <div className={styles.head}>
              <h2 className="alphabet">RECRUIT</h2>
            </div>
            <div className={styles.body}>
              <ul>
                <li>
                  <p className={styles.ttl}>採用について</p>
                  <div className={styles.txt}>
                    <p>弊社にご興味を持っていただきありがとうございます。</p>
                    <p>以下の職種を募集致します。</p>
                  </div>
                  <p className={styles.ttl}>募集職種</p>
                  <div className={styles.txt}>
                    <p>ブロックチェーンエンジニア</p>
                    <p>バックエンドエンジニア</p>
                    <p>フロントエンドエンジニア</p>
                    <p>UI／UXデザイナー</p>
                    <p>ディレクター</p>
                  </div>
                  <p className={styles.ttl}>連絡方法</p>
                  <div className={styles.txt}>
                    <p>メールアドレス：info@blockchain-connect.jp</p>
                    <p>電話番号：042-649-3361</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.contact} id="contact">
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <div className={styles.head}>
              <h2 className="alphabet">CONTACT</h2>
            </div>
            <div className={styles.body}>
              <ul>
                <li>
                  <p className={styles.ttl}>お問い合わせについて</p>
                  <div className={styles.txt}>
                    <p>弊社にご興味を持っていただきありがとうございます。</p>
                    <p>ビジネスに関するご質問やご相談など気軽にお問い合わせ下さいませ。</p>
                  </div>
                  <p className={styles.ttl}>お問い合わせ方法</p>
                  <div className={styles.txt}>
                    <p>メールアドレス：info@blockchain-connect.jp</p>
                    <p>電話番号：042-649-3361</p>
                    <p>ファックス：042-649-3362</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      
    </Layout>
  )
}

export default Company